$(document).ready(function() {
    $(window).scroll(function() {
        if (
            document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50
        ) {
            document.getElementById("scroll-up").style.display = "block";
        } else {
            document.getElementById("scroll-up").style.display = "none";
        }
    });
    $("#scroll-up").click(function() {
        $("body,html").animate(
            {
                scrollTop: 0
            },
            100
        );
        return false;
    });

    const menuBtn = $(".btn-target");
    menuBtn.click(() => {
        setTimeout(() => {
            removeHash();
        }, 5);
    });
    function removeHash() {
        history.replaceState(
            "",
            document.title,
            window.location.origin +
                window.location.pathname +
                window.location.search
        );
    }
});
