$(document).ready(function() {
    $(".custom-file-input").change(function(e) {
        $(this)
            .next(".custom-file-label")
            .html(e.target.files[0].name);
    });
    $("#btn-reset-file").on("click", function() {
        $(":file").val("");
        $(":file")
            .next(".custom-file-label")
            .replaceWith('<label class="custom-file-label text-nowrap overflow-hidden">Choose</label>');
    });
    $("#alert").modal("show");
    
});
