$(document).ready(function() {
    var owl = $(".sliders-carousel");
    owl.owlCarousel({
        items: 1,
        loop: true,
        lazyLoad: true,
        autoplay: true,
        nav: false,
        dots: false,
        navText: [
            "<div class='btn bg-teal'><i class='fas fa-caret-left mr-2'></i>Prev</div>",
            "<div class='btn bg-teal'>Next<i class='fas fa-caret-right ml-2'></i></div>"
        ],
        responsive: {
            0: {
                stagePadding: 20,
                margin: -20
            },
            576: {
                stagePadding: 30,
                margin: -30
            },
            768: {
                stagePadding: 50,
                margin: -50
            },
            992: {
                stagePadding: 70,
                margin: -70
            },
            1200: {
                stagePadding: 80,
                margin: -150
            }
        }
    });
    owl.on("mousewheel", ".owl-stage", function(e) {
        if (e.deltaY > 0) {
            owl.trigger("prev.owl");
        } else {
            owl.trigger("next.owl");
        }
        e.preventDefault();
    });

    var owlNews = $(".news-carousel");
    owlNews.owlCarousel({
        nav: false,
        navText: [
            "<div class='btn bg-teal'><i class='fas fa-caret-left mr-2'></i>Prev</div>",
            "<div class='btn bg-teal'>Next<i class='fas fa-caret-right ml-2'></i></div>"
        ],
        navClass: ["owl-prev", "owl-next"],
        dots: true,
        stagePadding: 0,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1
            },
            768: {
                items: 2,
            },
            992: {
                item: 3,
            },
        }
    });
    owlNews.on("mousewheel", ".owl-stage", function(e) {
        if (e.deltaY > 0) {
            owlNews.trigger("prev.owl");
        } else {
            owlNews.trigger("next.owl");
        }
        e.preventDefault();
    });

    var owlArticles = $(".articles-carousel");
    owlArticles.owlCarousel({
        nav: false,
        navText: [
            "<div class='btn bg-teal'><i class='fas fa-caret-left mr-2'></i>Prev</div>",
            "<div class='btn bg-teal'>Next<i class='fas fa-caret-right ml-2'></i></div>"
        ],
        navClass: ["owl-prev", "owl-next"],
        dots: true,
        stagePadding: 0,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1
            },
            768: {
                items: 2,
            },
            992: {
                item: 3,
            },
        }
    });
    owlArticles.on("mousewheel", ".owl-stage", function(e) {
        if (e.deltaY > 0) {
            owlArticles.trigger("prev.owl");
        } else {
            owlArticles.trigger("next.owl");
        }
        e.preventDefault();
    });

    var owlPlatinumPartnerships = $(".platinum-partnerships-carousel");
    owlPlatinumPartnerships.owlCarousel({
        // autoplay: true,
        // center: true,
        // loop: true,
        dots: true,
        lazyLoad: true,
        nav: false,
        items: 1,
        margin: 20,
        // autoWidth:true,
        stagePadding: 0,
        responsive: {
        //     0: {
        //         items: 1,
        //     },
        //     600: {
        //         items: 2,
        //     },
            1200: {
                items: 2,
                stagePadding: 110,
            }
        }
    });
    owlPlatinumPartnerships.on("mousewheel", ".owl-stage", function(e) {
        if (e.deltaY > 0) {
            owlPlatinumPartnerships.trigger("prev.owl");
        } else {
            owlPlatinumPartnerships.trigger("next.owl");
        }
        e.preventDefault();
    });

    var owlGoldPartnerships = $(".gold-partnerships-carousel");
    owlGoldPartnerships.owlCarousel({
        // autoplay: true,
        // center: true,
        // loop: true,
        dots: true,
        lazyLoad: true,
        nav: false,
        items: 1,
        margin: 20,
        // autoWidth:true,
        stagePadding: 0,
        responsive: {
        //     0: {
        //         items: 1,
        //     },
        //     600: {
        //         items: 2,
        //     },
            1200: {
                items: 2,
                stagePadding: 110,
            }
        }
    });
    owlGoldPartnerships.on("mousewheel", ".owl-stage", function(e) {
        if (e.deltaY > 0) {
            owlGoldPartnerships.trigger("prev.owl");
        } else {
            owlGoldPartnerships.trigger("next.owl");
        }
        e.preventDefault();
    });

    var owlSilverPartnerships = $(".silver-partnerships-carousel");
    owlSilverPartnerships.owlCarousel({
        // autoplay: true,
        // center: true,
        // loop: true,
        dots: true,
        lazyLoad: true,
        nav: false,
        items: 1,
        margin: 20,
        // autoWidth:true,
        stagePadding: 0,
        responsive: {
        //     0: {
        //         items: 1,
        //     },
        //     600: {
        //         items: 2,
        //     },
            1200: {
                items: 2,
                stagePadding: 110,
            }
        }
    });
    owlSilverPartnerships.on("mousewheel", ".owl-stage", function(e) {
        if (e.deltaY > 0) {
            owlSilverPartnerships.trigger("prev.owl");
        } else {
            owlSilverPartnerships.trigger("next.owl");
        }
        e.preventDefault();
    });

    var owlEvents = $(".events-carousel");
    owlEvents.owlCarousel({
        nav: false,
        navText: [
            "<div class='btn bg-teal'><i class='fas fa-caret-left mr-2'></i>Prev</div>",
            "<div class='btn bg-teal'>Next<i class='fas fa-caret-right ml-2'></i></div>"
        ],
        navClass: ["owl-prev", "owl-next"],
        dots: true,
        stagePadding: 0,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1
            },
            768: {
                items: 2,
            },
            992: {
                item: 3,
            },
        }
    });
    owlEvents.on("mousewheel", ".owl-stage", function(e) {
        if (e.deltaY > 0) {
            owlEvents.trigger("prev.owl");
        } else {
            owlEvents.trigger("next.owl");
        }
        e.preventDefault();
    });

    var owlAchievements = $(".achievement-carousel");
    owlAchievements.owlCarousel({
        autoplay: true,
        center: true,
        loop: true,
        // dots: true,
        lazyLoad: true,
        items: 1,
        margin: 10,
        // stagePadding: 50,
        nav: true,
        navText: [
            "<div class='nav-btn prev-slide shadow-sm'></div>",
            "<div class='nav-btn next-slide shadow-sm'></div>"
        ],
        responsive: {
            0: {
                items: 1
            },
            552: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 2
            },
            1200: {
                items: 2
            }
        }
    });
});
