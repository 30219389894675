$(document).ready(function() {
    $("#letterOuts").DataTable({
        processing: true,
        aaSorting: [],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 20,
        lengthMenu: [
            [20, 50, -1],
            [20, 50, "All"]
        ]
    });

    $("#letterOutsCompleted").DataTable({
        processing: true,
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        aaSorting: [],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 20,
        lengthMenu: [
            [20, 50, -1],
            [20, 50, "All"]
        ]
    });

    $("#letterOutsDeleted").DataTable({
        processing: true,
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        aaSorting: [],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 20,
        lengthMenu: [
            [20, 50, -1],
            [20, 50, "All"]
        ]
    });

    $("#reimbursements").DataTable({
        processing: true,
        aaSorting: [],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#reimbursementsApproved").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [4],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#reimbursementsDeleted").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [4],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#designRequests").DataTable({
        processing: true,
        aaSorting: [],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#designRequestsOnProgress").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [6],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#designRequestsCompleted").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [6],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#designRequestsDeleted").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [6],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#proofreadRequests").DataTable({
        processing: true,
        aaSorting: [],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#proofreadRequestsOnProgress").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [6],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#proofreadRequestsCompleted").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [6],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#proofreadRequestsDeleted").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [6],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#accessControls").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#achievementsVisible").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#achievementsHidden").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#achievementsTrashed").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#partnershipsVisible").DataTable({
        processing: true,
        order: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 10, 20, -1],
            [5, 10, 20, "All"]
        ]
    });
    $("#partnershipsHidden").DataTable({
        processing: true,
        order: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        responsive: true,
        pageLength: 5,
        lengthMenu: [
            [5, 10, 20, -1],
            [5, 10, 20, "All"]
        ]
    });
    $("#partnershipsTrashed").DataTable({
        processing: true,
        order: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 10, 20, -1],
            [5, 10, 20, "All"]
        ]
    });

    $("#headlinesVisible").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3, 4],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#headlinesHidden").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#headlinesTrashed").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [4],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#newsVisible").DataTable({
        processing: true,
        order: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 10, 20, -1],
            [5, 10, 20, "All"]
        ]
    });

    $("#newsHidden").DataTable({
        processing: true,
        order: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        responsive: true,
        pageLength: 5,
        lengthMenu: [
            [5, 10, 20, -1],
            [5, 10, 20, "All"]
        ]
    });

    $("#newsTrashed").DataTable({
        processing: true,
        order: [],
        columnDefs: [
            {
                targets: [4],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 10, 20, -1],
            [5, 10, 20, "All"]
        ]
    });

    $("#articlesVisible").DataTable({
        processing: true,
        order: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 10, 20, -1],
            [5, 10, 20, "All"]
        ]
    });

    $("#articlesHidden").DataTable({
        processing: true,
        order: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        responsive: true,
        pageLength: 5,
        lengthMenu: [
            [5, 10, 20, -1],
            [5, 10, 20, "All"]
        ]
    });

    $("#articlesTrashed").DataTable({
        processing: true,
        order: [],
        columnDefs: [
            {
                targets: [4],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 10, 20, -1],
            [5, 10, 20, "All"]
        ]
    });

    $("#eventSettings").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [4],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#eventsHidden").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#eventsTrashed").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#eventControls").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#eventParticipantBinusians").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#eventPaymentPending").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#eventPaymentConfirmed").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#binusianToeflRegistrant").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#nonBinusianToeflRegistrant").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

    $("#pendingToeflPayment").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 20,
        lengthMenu: [
            [20, 50, -1],
            [20, 50, "All"]
        ]
    });

    $("#confirmedToeflPayment").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [5],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 20,
        lengthMenu: [
            [20, 50, -1],
            [20, 50, "All"]
        ]
    });

    $("#BoMs").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [6],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [20, 50, -1],
            [20, 50, "All"]
        ]
    });

    $("#accessLists").DataTable({
        processing: true,
        aaSorting: [],
        columnDefs: [
            {
                targets: [3],
                orderable: false
            }
        ],
        dom:
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [
            [5, 25, -1],
            [5, 25, "All"]
        ]
    });

});
